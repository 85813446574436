import { useEffect, useState } from 'react'

export function useIsMobile () {
	// 여기서 window.innerWidth를 이용해 초기값을 잡아주면 아래에서
	// resize 이벤트를 발생시키지 않아도 되지만,
	// 그렇게 되면 SSG에 불편함이 생기므로 초기값을 true로 해줍니다.
	const [isMobile, setIsMobile] = useState(true)

	useEffect(() => {
		const onResize = () => { setIsMobile(window.innerWidth <= 800) }

		window.addEventListener('resize', onResize)
		window.dispatchEvent(new Event('resize')) // 첫 렌더링 때는 resize 이벤트가 발생하지 않기 때문에 직접 발생시켜줍니다.

		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	return isMobile
}
